$ci: #db052c;
$gray: rgb(42, 45, 46);

$light-blue: $ci;

$light-blue-400: lighten($ci, 10%);
$light-blue-700: $ci;
$brand-primary: $ci;
$brand-info: $gray;

@import "material-kit";

.navbar .navbar-nav > li > a {
  line-height: 35px;
}
.navbar .navbar-brand {
  line-height: 40px;
}

.center-pills {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .navbar .navbar-brand {
    line-height: 30px
  }
}