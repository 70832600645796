/* ANIMATION */
/* SHADOWS */
/* Shadows (from mdl http://www.getmdl.io/) */
body {
  background-color: #CCCCCC; }
  body.inverse {
    background: #333333; }
    body.inverse, body.inverse .form-control {
      color: #ffffff; }
    body.inverse .modal,
    body.inverse .modal .form-control,
    body.inverse .panel-default,
    body.inverse .panel-default .form-control,
    body.inverse .card,
    body.inverse .card .form-control {
      background-color: initial;
      color: initial; }

.life-of-material-kit {
  background: #FFFFFF; }

body, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em; }

a, a:hover, a:focus {
  color: #db052c; }
  a .material-icons, a:hover .material-icons, a:focus .material-icons {
    vertical-align: middle; }

/*           Animations              */
.animation-transition-general, .carousel .carousel-indicators li {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear; }

.animation-transition-slow {
  -webkit-transition: all 370ms linear;
  -moz-transition: all 370ms linear;
  -o-transition: all 370ms linear;
  -ms-transition: all 370ms linear;
  transition: all 370ms linear; }

.animation-transition-fast, .navbar {
  -webkit-transition: all 150ms ease 0s;
  -moz-transition: all 150ms ease 0s;
  -o-transition: all 150ms ease 0s;
  -ms-transition: all 150ms ease 0s;
  transition: all 150ms ease 0s; }

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 0; }

.form-horizontal .radio {
  margin-bottom: 10px; }

.form-horizontal label {
  text-align: right; }

.form-horizontal label.control-label {
  margin: 0; }

.btn,
.input-group-btn .btn {
  border: none;
  border-radius: 3px;
  position: relative;
  padding: 12px 30px;
  margin: 10px 1px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0;
  will-change: box-shadow, transform;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background: transparent; }
  .btn::-moz-focus-inner,
  .input-group-btn .btn::-moz-focus-inner {
    border: 0; }
  .btn:not(.btn-raised),
  .input-group-btn .btn:not(.btn-raised) {
    box-shadow: none; }
    .btn:not(.btn-raised), .btn:not(.btn-raised).btn-default,
    .input-group-btn .btn:not(.btn-raised),
    .input-group-btn .btn:not(.btn-raised).btn-default {
      color: rgba(0,0,0, 0.87); }
    .btn:not(.btn-raised).btn-inverse,
    .input-group-btn .btn:not(.btn-raised).btn-inverse {
      color: #3f51b5; }
    .btn:not(.btn-raised).btn-primary,
    .input-group-btn .btn:not(.btn-raised).btn-primary {
      color: #db052c; }
    .btn:not(.btn-raised).btn-success,
    .input-group-btn .btn:not(.btn-raised).btn-success {
      color: #4caf50; }
    .btn:not(.btn-raised).btn-info,
    .input-group-btn .btn:not(.btn-raised).btn-info {
      color: #2a2d2e; }
    .btn:not(.btn-raised).btn-warning,
    .input-group-btn .btn:not(.btn-raised).btn-warning {
      color: #fbc02d; }
    .btn:not(.btn-raised).btn-danger,
    .input-group-btn .btn:not(.btn-raised).btn-danger {
      color: #f44336; }
    .btn:not(.btn-raised):not(.btn-link):hover, .btn:not(.btn-raised):not(.btn-link):focus,
    .input-group-btn .btn:not(.btn-raised):not(.btn-link):hover,
    .input-group-btn .btn:not(.btn-raised):not(.btn-link):focus {
      background-color: transparent; }
  .btn:focus, .btn:active, .btn:active:focus,
  .input-group-btn .btn:focus,
  .input-group-btn .btn:active,
  .input-group-btn .btn:active:focus {
    outline: 0; }
  .btn.btn-raised, .btn.btn-raised.btn-default, .btn.btn-fab, .btn.btn-fab.btn-default,
  .btn-group-raised .btn,
  .btn-group-raised .btn.btn-default,
  .input-group-btn .btn.btn-raised,
  .input-group-btn .btn.btn-raised.btn-default,
  .input-group-btn .btn.btn-fab,
  .input-group-btn .btn.btn-fab.btn-default,
  .btn-group-raised
  .input-group-btn .btn,
  .btn-group-raised
  .input-group-btn .btn.btn-default {
    background-color: #EEEEEE;
    color: rgba(0,0,0, 0.87); }
  .btn.btn-raised.btn-inverse, .btn.btn-fab.btn-inverse,
  .btn-group-raised .btn.btn-inverse,
  .input-group-btn .btn.btn-raised.btn-inverse,
  .input-group-btn .btn.btn-fab.btn-inverse,
  .btn-group-raised
  .input-group-btn .btn.btn-inverse {
    background-color: #3f51b5;
    color: contrast-color(#3f51b5, #000000, #ffffff); }
  .btn.btn-raised.btn-primary, .btn.btn-fab.btn-primary,
  .btn-group-raised .btn.btn-primary,
  .input-group-btn .btn.btn-raised.btn-primary,
  .input-group-btn .btn.btn-fab.btn-primary,
  .btn-group-raised
  .input-group-btn .btn.btn-primary {
    background-color: #db052c;
    color: #ffffff; }
  .btn.btn-raised.btn-success, .btn.btn-fab.btn-success,
  .btn-group-raised .btn.btn-success,
  .input-group-btn .btn.btn-raised.btn-success,
  .input-group-btn .btn.btn-fab.btn-success,
  .btn-group-raised
  .input-group-btn .btn.btn-success {
    background-color: #4caf50;
    color: #ffffff; }
  .btn.btn-raised.btn-info, .btn.btn-fab.btn-info,
  .btn-group-raised .btn.btn-info,
  .input-group-btn .btn.btn-raised.btn-info,
  .input-group-btn .btn.btn-fab.btn-info,
  .btn-group-raised
  .input-group-btn .btn.btn-info {
    background-color: #2a2d2e;
    color: #ffffff; }
  .btn.btn-raised.btn-warning, .btn.btn-fab.btn-warning,
  .btn-group-raised .btn.btn-warning,
  .input-group-btn .btn.btn-raised.btn-warning,
  .input-group-btn .btn.btn-fab.btn-warning,
  .btn-group-raised
  .input-group-btn .btn.btn-warning {
    background-color: #fbc02d;
    color: #ffffff; }
  .btn.btn-raised.btn-danger, .btn.btn-fab.btn-danger,
  .btn-group-raised .btn.btn-danger,
  .input-group-btn .btn.btn-raised.btn-danger,
  .input-group-btn .btn.btn-fab.btn-danger,
  .btn-group-raised
  .input-group-btn .btn.btn-danger {
    background-color: #f44336;
    color: #ffffff; }
  .btn.btn-raised:not(.btn-link),
  .btn-group-raised .btn:not(.btn-link),
  .input-group-btn .btn.btn-raised:not(.btn-link),
  .btn-group-raised
  .input-group-btn .btn:not(.btn-link) {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
    .btn.btn-raised:not(.btn-link):hover, .btn.btn-raised:not(.btn-link):focus, .btn.btn-raised:not(.btn-link).active, .btn.btn-raised:not(.btn-link):active,
    .btn-group-raised .btn:not(.btn-link):hover,
    .btn-group-raised .btn:not(.btn-link):focus,
    .btn-group-raised .btn:not(.btn-link).active,
    .btn-group-raised .btn:not(.btn-link):active,
    .input-group-btn .btn.btn-raised:not(.btn-link):hover,
    .input-group-btn .btn.btn-raised:not(.btn-link):focus,
    .input-group-btn .btn.btn-raised:not(.btn-link).active,
    .input-group-btn .btn.btn-raised:not(.btn-link):active,
    .btn-group-raised
    .input-group-btn .btn:not(.btn-link):hover,
    .btn-group-raised
    .input-group-btn .btn:not(.btn-link):focus,
    .btn-group-raised
    .input-group-btn .btn:not(.btn-link).active,
    .btn-group-raised
    .input-group-btn .btn:not(.btn-link):active {
      outline: 0; }
      .btn.btn-raised:not(.btn-link):hover, .btn.btn-raised:not(.btn-link):hover.btn-default, .btn.btn-raised:not(.btn-link):focus, .btn.btn-raised:not(.btn-link):focus.btn-default, .btn.btn-raised:not(.btn-link).active, .btn.btn-raised:not(.btn-link).active.btn-default, .btn.btn-raised:not(.btn-link):active, .btn.btn-raised:not(.btn-link):active.btn-default,
      .btn-group-raised .btn:not(.btn-link):hover,
      .btn-group-raised .btn:not(.btn-link):hover.btn-default,
      .btn-group-raised .btn:not(.btn-link):focus,
      .btn-group-raised .btn:not(.btn-link):focus.btn-default,
      .btn-group-raised .btn:not(.btn-link).active,
      .btn-group-raised .btn:not(.btn-link).active.btn-default,
      .btn-group-raised .btn:not(.btn-link):active,
      .btn-group-raised .btn:not(.btn-link):active.btn-default,
      .input-group-btn .btn.btn-raised:not(.btn-link):hover,
      .input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-default,
      .input-group-btn .btn.btn-raised:not(.btn-link):focus,
      .input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-default,
      .input-group-btn .btn.btn-raised:not(.btn-link).active,
      .input-group-btn .btn.btn-raised:not(.btn-link).active.btn-default,
      .input-group-btn .btn.btn-raised:not(.btn-link):active,
      .input-group-btn .btn.btn-raised:not(.btn-link):active.btn-default,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):hover,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):hover.btn-default,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):focus,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):focus.btn-default,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link).active,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link).active.btn-default,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):active,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):active.btn-default {
        background-color: contrast-color(#EEEEEE, #e4e4e4, #f8f8f8); }
      .btn.btn-raised:not(.btn-link):hover.btn-inverse, .btn.btn-raised:not(.btn-link):focus.btn-inverse, .btn.btn-raised:not(.btn-link).active.btn-inverse, .btn.btn-raised:not(.btn-link):active.btn-inverse,
      .btn-group-raised .btn:not(.btn-link):hover.btn-inverse,
      .btn-group-raised .btn:not(.btn-link):focus.btn-inverse,
      .btn-group-raised .btn:not(.btn-link).active.btn-inverse,
      .btn-group-raised .btn:not(.btn-link):active.btn-inverse,
      .input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-inverse,
      .input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-inverse,
      .input-group-btn .btn.btn-raised:not(.btn-link).active.btn-inverse,
      .input-group-btn .btn.btn-raised:not(.btn-link):active.btn-inverse,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):hover.btn-inverse,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):focus.btn-inverse,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link).active.btn-inverse,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):active.btn-inverse {
        background-color: contrast-color(#3f51b5, #3a4aa6, #495bc0); }
      .btn.btn-raised:not(.btn-link):hover.btn-primary, .btn.btn-raised:not(.btn-link):focus.btn-primary, .btn.btn-raised:not(.btn-link).active.btn-primary, .btn.btn-raised:not(.btn-link):active.btn-primary,
      .btn-group-raised .btn:not(.btn-link):hover.btn-primary,
      .btn-group-raised .btn:not(.btn-link):focus.btn-primary,
      .btn-group-raised .btn:not(.btn-link).active.btn-primary,
      .btn-group-raised .btn:not(.btn-link):active.btn-primary,
      .input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-primary,
      .input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-primary,
      .input-group-btn .btn.btn-raised:not(.btn-link).active.btn-primary,
      .input-group-btn .btn.btn-raised:not(.btn-link):active.btn-primary,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):hover.btn-primary,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):focus.btn-primary,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link).active.btn-primary,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):active.btn-primary {
        background-color: contrast-color(#db052c, #c70528, #ef0530); }
      .btn.btn-raised:not(.btn-link):hover.btn-success, .btn.btn-raised:not(.btn-link):focus.btn-success, .btn.btn-raised:not(.btn-link).active.btn-success, .btn.btn-raised:not(.btn-link):active.btn-success,
      .btn-group-raised .btn:not(.btn-link):hover.btn-success,
      .btn-group-raised .btn:not(.btn-link):focus.btn-success,
      .btn-group-raised .btn:not(.btn-link).active.btn-success,
      .btn-group-raised .btn:not(.btn-link):active.btn-success,
      .input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-success,
      .input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-success,
      .input-group-btn .btn.btn-raised:not(.btn-link).active.btn-success,
      .input-group-btn .btn.btn-raised:not(.btn-link):active.btn-success,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):hover.btn-success,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):focus.btn-success,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link).active.btn-success,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):active.btn-success {
        background-color: contrast-color(#4caf50, #46a149, #59b75c); }
      .btn.btn-raised:not(.btn-link):hover.btn-info, .btn.btn-raised:not(.btn-link):focus.btn-info, .btn.btn-raised:not(.btn-link).active.btn-info, .btn.btn-raised:not(.btn-link):active.btn-info,
      .btn-group-raised .btn:not(.btn-link):hover.btn-info,
      .btn-group-raised .btn:not(.btn-link):focus.btn-info,
      .btn-group-raised .btn:not(.btn-link).active.btn-info,
      .btn-group-raised .btn:not(.btn-link):active.btn-info,
      .input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-info,
      .input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-info,
      .input-group-btn .btn.btn-raised:not(.btn-link).active.btn-info,
      .input-group-btn .btn.btn-raised:not(.btn-link):active.btn-info,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):hover.btn-info,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):focus.btn-info,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link).active.btn-info,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):active.btn-info {
        background-color: contrast-color(#2a2d2e, #202323, #343739); }
      .btn.btn-raised:not(.btn-link):hover.btn-warning, .btn.btn-raised:not(.btn-link):focus.btn-warning, .btn.btn-raised:not(.btn-link).active.btn-warning, .btn.btn-raised:not(.btn-link):active.btn-warning,
      .btn-group-raised .btn:not(.btn-link):hover.btn-warning,
      .btn-group-raised .btn:not(.btn-link):focus.btn-warning,
      .btn-group-raised .btn:not(.btn-link).active.btn-warning,
      .btn-group-raised .btn:not(.btn-link):active.btn-warning,
      .input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-warning,
      .input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-warning,
      .input-group-btn .btn.btn-raised:not(.btn-link).active.btn-warning,
      .input-group-btn .btn.btn-raised:not(.btn-link):active.btn-warning,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):hover.btn-warning,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):focus.btn-warning,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link).active.btn-warning,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):active.btn-warning {
        background-color: contrast-color(#fbc02d, #fbba19, #fbc641); }
      .btn.btn-raised:not(.btn-link):hover.btn-danger, .btn.btn-raised:not(.btn-link):focus.btn-danger, .btn.btn-raised:not(.btn-link).active.btn-danger, .btn.btn-raised:not(.btn-link):active.btn-danger,
      .btn-group-raised .btn:not(.btn-link):hover.btn-danger,
      .btn-group-raised .btn:not(.btn-link):focus.btn-danger,
      .btn-group-raised .btn:not(.btn-link).active.btn-danger,
      .btn-group-raised .btn:not(.btn-link):active.btn-danger,
      .input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-danger,
      .input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-danger,
      .input-group-btn .btn.btn-raised:not(.btn-link).active.btn-danger,
      .input-group-btn .btn.btn-raised:not(.btn-link):active.btn-danger,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):hover.btn-danger,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):focus.btn-danger,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link).active.btn-danger,
      .btn-group-raised
      .input-group-btn .btn:not(.btn-link):active.btn-danger {
        background-color: contrast-color(#f44336, #f33123, #f55549); }
    .btn.btn-raised:not(.btn-link):hover, .btn.btn-raised:not(.btn-link):hover:focus, .btn.btn-raised:not(.btn-link):hover.active, .btn.btn-raised:not(.btn-link):hover.active:focus, .btn.btn-raised:not(.btn-link):hover:active, .btn.btn-raised:not(.btn-link):hover:active:focus,
    .btn-group-raised .btn:not(.btn-link):hover,
    .btn-group-raised .btn:not(.btn-link):hover:focus,
    .btn-group-raised .btn:not(.btn-link):hover.active,
    .btn-group-raised .btn:not(.btn-link):hover.active:focus,
    .btn-group-raised .btn:not(.btn-link):hover:active,
    .btn-group-raised .btn:not(.btn-link):hover:active:focus,
    .input-group-btn .btn.btn-raised:not(.btn-link):hover,
    .input-group-btn .btn.btn-raised:not(.btn-link):hover:focus,
    .input-group-btn .btn.btn-raised:not(.btn-link):hover.active,
    .input-group-btn .btn.btn-raised:not(.btn-link):hover.active:focus,
    .input-group-btn .btn.btn-raised:not(.btn-link):hover:active,
    .input-group-btn .btn.btn-raised:not(.btn-link):hover:active:focus,
    .btn-group-raised
    .input-group-btn .btn:not(.btn-link):hover,
    .btn-group-raised
    .input-group-btn .btn:not(.btn-link):hover:focus,
    .btn-group-raised
    .input-group-btn .btn:not(.btn-link):hover.active,
    .btn-group-raised
    .input-group-btn .btn:not(.btn-link):hover.active:focus,
    .btn-group-raised
    .input-group-btn .btn:not(.btn-link):hover:active,
    .btn-group-raised
    .input-group-btn .btn:not(.btn-link):hover:active:focus {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36); }
  .btn.btn-round,
  .input-group-btn .btn.btn-round {
    border-radius: 30px; }
  .btn i.material-icons,
  .input-group-btn .btn i.material-icons {
    vertical-align: middle;
    font-size: 17px;
    top: -1px;
    position: relative; }
  .btn.btn-fab,
  .input-group-btn .btn.btn-fab {
    border-radius: 50%;
    font-size: 24px;
    height: 56px;
    margin: auto;
    min-width: 56px;
    width: 56px;
    padding: 0;
    overflow: hidden;
    box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    position: relative;
    line-height: normal; }
    .btn.btn-fab .ripple-container,
    .input-group-btn .btn.btn-fab .ripple-container {
      border-radius: 50%; }
    .btn.btn-fab.btn-fab-mini,
    .btn-group-sm .btn.btn-fab,
    .input-group-btn .btn.btn-fab.btn-fab-mini,
    .btn-group-sm
    .input-group-btn .btn.btn-fab {
      height: 40px;
      min-width: 40px;
      width: 40px; }
      .btn.btn-fab.btn-fab-mini.material-icons,
      .btn-group-sm .btn.btn-fab.material-icons,
      .input-group-btn .btn.btn-fab.btn-fab-mini.material-icons,
      .btn-group-sm
      .input-group-btn .btn.btn-fab.material-icons {
        top: -3.5px;
        left: -3.5px; }
      .btn.btn-fab.btn-fab-mini .material-icons,
      .btn-group-sm .btn.btn-fab .material-icons,
      .input-group-btn .btn.btn-fab.btn-fab-mini .material-icons,
      .btn-group-sm
      .input-group-btn .btn.btn-fab .material-icons {
        font-size: 17px; }
    .btn.btn-fab i.material-icons,
    .input-group-btn .btn.btn-fab i.material-icons {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-12px, -12px);
      line-height: 24px;
      width: 24px;
      font-size: 24px; }
  .btn.btn-lg,
  .btn-group-lg .btn,
  .input-group-btn .btn.btn-lg,
  .btn-group-lg
  .input-group-btn .btn {
    font-size: 14px;
    padding: 18px 36px; }
  .btn.btn-sm,
  .btn-group-sm .btn,
  .input-group-btn .btn.btn-sm,
  .btn-group-sm
  .input-group-btn .btn {
    padding: 5px 20px;
    font-size: 11px; }
  .btn.btn-xs,
  .btn-group-xs .btn,
  .input-group-btn .btn.btn-xs,
  .btn-group-xs
  .input-group-btn .btn {
    padding: 4px 15px;
    font-size: 10px; }

.btn-just-icon {
  font-size: 20px;
  padding: 6px 11px; }

fieldset[disabled][disabled] .btn, .btn.disabled, .btn:disabled, .btn[disabled][disabled], fieldset[disabled][disabled]
.input-group-btn .btn,
.input-group-btn .btn.disabled,
.input-group-btn .btn:disabled,
.input-group-btn .btn[disabled][disabled], fieldset[disabled][disabled]
.btn-group,
.btn-group.disabled,
.btn-group:disabled,
.btn-group[disabled][disabled], fieldset[disabled][disabled]
.btn-group-vertical,
.btn-group-vertical.disabled,
.btn-group-vertical:disabled,
.btn-group-vertical[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
  background: transparent; }
  .theme-dark fieldset[disabled][disabled] .btn, .theme-dark .btn.disabled, .theme-dark .btn:disabled, .theme-dark .btn[disabled][disabled], .theme-dark fieldset[disabled][disabled]
  .input-group-btn .btn, .theme-dark
  .input-group-btn .btn.disabled, .theme-dark
  .input-group-btn .btn:disabled, .theme-dark
  .input-group-btn .btn[disabled][disabled], .theme-dark fieldset[disabled][disabled]
  .btn-group, .theme-dark
  .btn-group.disabled, .theme-dark
  .btn-group:disabled, .theme-dark
  .btn-group[disabled][disabled], .theme-dark fieldset[disabled][disabled]
  .btn-group-vertical, .theme-dark
  .btn-group-vertical.disabled, .theme-dark
  .btn-group-vertical:disabled, .theme-dark
  .btn-group-vertical[disabled][disabled] {
    color: rgba(255, 255, 255, 0.3); }
  fieldset[disabled][disabled] .btn.btn-raised, fieldset[disabled][disabled] .btn.btn-raised.active, fieldset[disabled][disabled] .btn.btn-raised:active, fieldset[disabled][disabled] .btn.btn-raised:focus:not(:active), fieldset[disabled][disabled] .btn.btn-group-raised, fieldset[disabled][disabled] .btn.btn-group-raised.active, fieldset[disabled][disabled] .btn.btn-group-raised:active, fieldset[disabled][disabled] .btn.btn-group-raised:focus:not(:active), .btn.disabled.btn-raised, .btn.disabled.btn-raised.active, .btn.disabled.btn-raised:active, .btn.disabled.btn-raised:focus:not(:active), .btn.disabled.btn-group-raised, .btn.disabled.btn-group-raised.active, .btn.disabled.btn-group-raised:active, .btn.disabled.btn-group-raised:focus:not(:active), .btn:disabled.btn-raised, .btn:disabled.btn-raised.active, .btn:disabled.btn-raised:active, .btn:disabled.btn-raised:focus:not(:active), .btn:disabled.btn-group-raised, .btn:disabled.btn-group-raised.active, .btn:disabled.btn-group-raised:active, .btn:disabled.btn-group-raised:focus:not(:active), .btn[disabled][disabled].btn-raised, .btn[disabled][disabled].btn-raised.active, .btn[disabled][disabled].btn-raised:active, .btn[disabled][disabled].btn-raised:focus:not(:active), .btn[disabled][disabled].btn-group-raised, .btn[disabled][disabled].btn-group-raised.active, .btn[disabled][disabled].btn-group-raised:active, .btn[disabled][disabled].btn-group-raised:focus:not(:active), fieldset[disabled][disabled]
  .input-group-btn .btn.btn-raised, fieldset[disabled][disabled]
  .input-group-btn .btn.btn-raised.active, fieldset[disabled][disabled]
  .input-group-btn .btn.btn-raised:active, fieldset[disabled][disabled]
  .input-group-btn .btn.btn-raised:focus:not(:active), fieldset[disabled][disabled]
  .input-group-btn .btn.btn-group-raised, fieldset[disabled][disabled]
  .input-group-btn .btn.btn-group-raised.active, fieldset[disabled][disabled]
  .input-group-btn .btn.btn-group-raised:active, fieldset[disabled][disabled]
  .input-group-btn .btn.btn-group-raised:focus:not(:active),
  .input-group-btn .btn.disabled.btn-raised,
  .input-group-btn .btn.disabled.btn-raised.active,
  .input-group-btn .btn.disabled.btn-raised:active,
  .input-group-btn .btn.disabled.btn-raised:focus:not(:active),
  .input-group-btn .btn.disabled.btn-group-raised,
  .input-group-btn .btn.disabled.btn-group-raised.active,
  .input-group-btn .btn.disabled.btn-group-raised:active,
  .input-group-btn .btn.disabled.btn-group-raised:focus:not(:active),
  .input-group-btn .btn:disabled.btn-raised,
  .input-group-btn .btn:disabled.btn-raised.active,
  .input-group-btn .btn:disabled.btn-raised:active,
  .input-group-btn .btn:disabled.btn-raised:focus:not(:active),
  .input-group-btn .btn:disabled.btn-group-raised,
  .input-group-btn .btn:disabled.btn-group-raised.active,
  .input-group-btn .btn:disabled.btn-group-raised:active,
  .input-group-btn .btn:disabled.btn-group-raised:focus:not(:active),
  .input-group-btn .btn[disabled][disabled].btn-raised,
  .input-group-btn .btn[disabled][disabled].btn-raised.active,
  .input-group-btn .btn[disabled][disabled].btn-raised:active,
  .input-group-btn .btn[disabled][disabled].btn-raised:focus:not(:active),
  .input-group-btn .btn[disabled][disabled].btn-group-raised,
  .input-group-btn .btn[disabled][disabled].btn-group-raised.active,
  .input-group-btn .btn[disabled][disabled].btn-group-raised:active,
  .input-group-btn .btn[disabled][disabled].btn-group-raised:focus:not(:active), fieldset[disabled][disabled]
  .btn-group.btn-raised, fieldset[disabled][disabled]
  .btn-group.btn-raised.active, fieldset[disabled][disabled]
  .btn-group.btn-raised:active, fieldset[disabled][disabled]
  .btn-group.btn-raised:focus:not(:active), fieldset[disabled][disabled]
  .btn-group.btn-group-raised, fieldset[disabled][disabled]
  .btn-group.btn-group-raised.active, fieldset[disabled][disabled]
  .btn-group.btn-group-raised:active, fieldset[disabled][disabled]
  .btn-group.btn-group-raised:focus:not(:active),
  .btn-group.disabled.btn-raised,
  .btn-group.disabled.btn-raised.active,
  .btn-group.disabled.btn-raised:active,
  .btn-group.disabled.btn-raised:focus:not(:active),
  .btn-group.disabled.btn-group-raised,
  .btn-group.disabled.btn-group-raised.active,
  .btn-group.disabled.btn-group-raised:active,
  .btn-group.disabled.btn-group-raised:focus:not(:active),
  .btn-group:disabled.btn-raised,
  .btn-group:disabled.btn-raised.active,
  .btn-group:disabled.btn-raised:active,
  .btn-group:disabled.btn-raised:focus:not(:active),
  .btn-group:disabled.btn-group-raised,
  .btn-group:disabled.btn-group-raised.active,
  .btn-group:disabled.btn-group-raised:active,
  .btn-group:disabled.btn-group-raised:focus:not(:active),
  .btn-group[disabled][disabled].btn-raised,
  .btn-group[disabled][disabled].btn-raised.active,
  .btn-group[disabled][disabled].btn-raised:active,
  .btn-group[disabled][disabled].btn-raised:focus:not(:active),
  .btn-group[disabled][disabled].btn-group-raised,
  .btn-group[disabled][disabled].btn-group-raised.active,
  .btn-group[disabled][disabled].btn-group-raised:active,
  .btn-group[disabled][disabled].btn-group-raised:focus:not(:active), fieldset[disabled][disabled]
  .btn-group-vertical.btn-raised, fieldset[disabled][disabled]
  .btn-group-vertical.btn-raised.active, fieldset[disabled][disabled]
  .btn-group-vertical.btn-raised:active, fieldset[disabled][disabled]
  .btn-group-vertical.btn-raised:focus:not(:active), fieldset[disabled][disabled]
  .btn-group-vertical.btn-group-raised, fieldset[disabled][disabled]
  .btn-group-vertical.btn-group-raised.active, fieldset[disabled][disabled]
  .btn-group-vertical.btn-group-raised:active, fieldset[disabled][disabled]
  .btn-group-vertical.btn-group-raised:focus:not(:active),
  .btn-group-vertical.disabled.btn-raised,
  .btn-group-vertical.disabled.btn-raised.active,
  .btn-group-vertical.disabled.btn-raised:active,
  .btn-group-vertical.disabled.btn-raised:focus:not(:active),
  .btn-group-vertical.disabled.btn-group-raised,
  .btn-group-vertical.disabled.btn-group-raised.active,
  .btn-group-vertical.disabled.btn-group-raised:active,
  .btn-group-vertical.disabled.btn-group-raised:focus:not(:active),
  .btn-group-vertical:disabled.btn-raised,
  .btn-group-vertical:disabled.btn-raised.active,
  .btn-group-vertical:disabled.btn-raised:active,
  .btn-group-vertical:disabled.btn-raised:focus:not(:active),
  .btn-group-vertical:disabled.btn-group-raised,
  .btn-group-vertical:disabled.btn-group-raised.active,
  .btn-group-vertical:disabled.btn-group-raised:active,
  .btn-group-vertical:disabled.btn-group-raised:focus:not(:active),
  .btn-group-vertical[disabled][disabled].btn-raised,
  .btn-group-vertical[disabled][disabled].btn-raised.active,
  .btn-group-vertical[disabled][disabled].btn-raised:active,
  .btn-group-vertical[disabled][disabled].btn-raised:focus:not(:active),
  .btn-group-vertical[disabled][disabled].btn-group-raised,
  .btn-group-vertical[disabled][disabled].btn-group-raised.active,
  .btn-group-vertical[disabled][disabled].btn-group-raised:active,
  .btn-group-vertical[disabled][disabled].btn-group-raised:focus:not(:active) {
    box-shadow: none; }

.btn-group,
.btn-group-vertical {
  position: relative;
  margin: 10px 1px; }
  .btn-group.open > .dropdown-toggle.btn, .btn-group.open > .dropdown-toggle.btn.btn-default,
  .btn-group-vertical.open > .dropdown-toggle.btn,
  .btn-group-vertical.open > .dropdown-toggle.btn.btn-default {
    background-color: #EEEEEE; }
  .btn-group.open > .dropdown-toggle.btn.btn-inverse,
  .btn-group-vertical.open > .dropdown-toggle.btn.btn-inverse {
    background-color: #3f51b5; }
  .btn-group.open > .dropdown-toggle.btn.btn-primary,
  .btn-group-vertical.open > .dropdown-toggle.btn.btn-primary {
    background-color: #db052c; }
  .btn-group.open > .dropdown-toggle.btn.btn-success,
  .btn-group-vertical.open > .dropdown-toggle.btn.btn-success {
    background-color: #4caf50; }
  .btn-group.open > .dropdown-toggle.btn.btn-info,
  .btn-group-vertical.open > .dropdown-toggle.btn.btn-info {
    background-color: #2a2d2e; }
  .btn-group.open > .dropdown-toggle.btn.btn-warning,
  .btn-group-vertical.open > .dropdown-toggle.btn.btn-warning {
    background-color: #fbc02d; }
  .btn-group.open > .dropdown-toggle.btn.btn-danger,
  .btn-group-vertical.open > .dropdown-toggle.btn.btn-danger {
    background-color: #f44336; }
  .btn-group .dropdown-menu,
  .btn-group-vertical .dropdown-menu {
    border-radius: 0 0 3px 3px; }
  .btn-group.btn-group-raised,
  .btn-group-vertical.btn-group-raised {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .btn-group .btn + .btn,
  .btn-group .btn,
  .btn-group .btn:active,
  .btn-group .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn,
  .btn-group-vertical .btn:active,
  .btn-group-vertical .btn-group {
    margin: 0; }

.close {
  font-size: inherit;
  color: #FFFFFF;
  opacity: .9;
  text-shadow: none; }
  .close:hover, .close:focus {
    opacity: 1;
    color: #FFFFFF; }
  .close i {
    font-size: 20px; }

.checkbox label {
  cursor: pointer;
  padding-left: 0;
  color: rgba(0,0,0, 0.26); }
  .form-group.is-focused .checkbox label {
    color: rgba(0,0,0, 0.26); }
    .form-group.is-focused .checkbox label:hover, .form-group.is-focused .checkbox label:focus {
      color: rgba(0,0,0, .54); }
    fieldset[disabled] .form-group.is-focused .checkbox label {
      color: rgba(0,0,0, 0.26); }

.checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  pointer-events: none; }

.checkbox .checkbox-material {
  vertical-align: middle;
  position: relative;
  top: 3px;
  padding-right: 5px; }
  .checkbox .checkbox-material:before {
    display: block;
    position: absolute;
    left: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.84);
    height: 20px;
    width: 20px;
    border-radius: 100%;
    z-index: 1;
    opacity: 0;
    margin: 0;
    transform: scale3d(2.3, 2.3, 1); }
  .checkbox .checkbox-material .check {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(0,0,0, .54);
    overflow: hidden;
    z-index: 1;
    border-radius: 3px; }
  .checkbox .checkbox-material .check:before {
    position: absolute;
    content: "";
    transform: rotate(45deg);
    display: block;
    margin-top: -3px;
    margin-left: 7px;
    width: 0;
    height: 0;
    background: red;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
    animation: checkbox-off 0.3s forwards; }

.checkbox input[type=checkbox]:focus + .checkbox-material .check:after {
  opacity: 0.2; }

.checkbox input[type=checkbox]:checked + .checkbox-material .check {
  background: #db052c; }

.checkbox input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #FFFFFF;
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  animation: checkbox-on 0.3s forwards; }

.checkbox input[type=checkbox]:checked + .checkbox-material:before {
  animation: rippleOn 500ms; }

.checkbox input[type=checkbox]:checked + .checkbox-material .check:after {
  animation: rippleOn 500ms forwards; }

.checkbox input[type=checkbox]:not(:checked) + .checkbox-material:before {
  animation: rippleOff 500ms; }

.checkbox input[type=checkbox]:not(:checked) + .checkbox-material .check:after {
  animation: rippleOff 500ms; }

fieldset[disabled] .checkbox,
fieldset[disabled] .checkbox input[type=checkbox],
.checkbox input[type=checkbox][disabled] ~ .checkbox-material .check,
.checkbox input[type=checkbox][disabled] + .circle {
  opacity: 0.5; }

.checkbox input[type=checkbox][disabled] ~ .checkbox-material .check {
  border-color: #000000;
  opacity: .26; }

.checkbox input[type=checkbox][disabled] + .checkbox-material .check:after {
  background-color: rgba(0,0,0, 0.87);
  transform: rotate(-45deg); }

@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px; }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px; }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px; } }

@keyframes rippleOn {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.2; }
  100% {
    opacity: 0; } }

@keyframes rippleOff {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.2; }
  100% {
    opacity: 0; } }

.togglebutton {
  vertical-align: middle; }
  .togglebutton, .togglebutton label, .togglebutton input, .togglebutton .toggle {
    user-select: none; }
  .togglebutton label {
    cursor: pointer;
    color: rgba(0,0,0, 0.26); }
    .form-group.is-focused .togglebutton label {
      color: rgba(0,0,0, 0.26); }
      .form-group.is-focused .togglebutton label:hover, .form-group.is-focused .togglebutton label:focus {
        color: rgba(0,0,0, .54); }
      fieldset[disabled] .form-group.is-focused .togglebutton label {
        color: rgba(0,0,0, 0.26); }
    .togglebutton label input[type=checkbox] {
      opacity: 0;
      width: 0;
      height: 0; }
    .togglebutton label .toggle {
      text-align: left;
      margin-left: 5px; }
    .togglebutton label .toggle,
    .togglebutton label input[type=checkbox][disabled] + .toggle {
      content: "";
      display: inline-block;
      width: 30px;
      height: 15px;
      background-color: rgba(80, 80, 80, 0.7);
      border-radius: 15px;
      margin-right: 15px;
      transition: background 0.3s ease;
      vertical-align: middle; }
    .togglebutton label .toggle:after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #FFFFFF;
      border-radius: 20px;
      position: relative;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
      left: -5px;
      top: -3px;
      border: 1px solid rgba(0,0,0, .54);
      transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease; }
    .togglebutton label input[type=checkbox][disabled] + .toggle:after,
    .togglebutton label input[type=checkbox][disabled]:checked + .toggle:after {
      background-color: #BDBDBD; }
    .togglebutton label input[type=checkbox] + .toggle:active:after,
    .togglebutton label input[type=checkbox][disabled] + .toggle:active:after {
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1); }
    .togglebutton label input[type=checkbox]:checked + .toggle:after {
      left: 15px; }
    .togglebutton label input[type=checkbox]:checked + .toggle {
      background-color: rgba(219, 5, 44, 0.7); }
    .togglebutton label input[type=checkbox]:checked + .toggle:after {
      border-color: #db052c; }
    .togglebutton label input[type=checkbox]:checked + .toggle:active:after {
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(219, 5, 44, 0.1); }

.radio label {
  cursor: pointer;
  padding-left: 35px;
  position: relative;
  color: rgba(0,0,0, 0.26); }
  .form-group.is-focused .radio label {
    color: rgba(0,0,0, 0.26); }
    .form-group.is-focused .radio label:hover, .form-group.is-focused .radio label:focus {
      color: rgba(0,0,0, .54); }
    fieldset[disabled] .form-group.is-focused .radio label {
      color: rgba(0,0,0, 0.26); }
  .radio label span {
    display: block;
    position: absolute;
    left: 10px;
    top: 2px;
    transition-duration: 0.2s; }
  .radio label .circle {
    border: 1px solid rgba(0,0,0, .54);
    height: 15px;
    width: 15px;
    border-radius: 100%; }
  .radio label .check {
    height: 15px;
    width: 15px;
    border-radius: 100%;
    background-color: #db052c;
    transform: scale3d(0, 0, 0); }
  .radio label .check:after {
    display: block;
    position: absolute;
    content: "";
    background-color: rgba(0,0,0, 0.87);
    left: -18px;
    top: -18px;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    z-index: 1;
    opacity: 0;
    margin: 0;
    transform: scale3d(1.5, 1.5, 1); }
  .radio label input[type=radio]:not(:checked) ~ .check:after {
    animation: rippleOff 500ms; }
  .radio label input[type=radio]:checked ~ .check:after {
    animation: rippleOn 500ms; }

.radio input[type=radio] {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden; }
  .radio input[type=radio]:checked ~ .check,
  .radio input[type=radio]:checked ~ .circle {
    opacity: 1; }
  .radio input[type=radio]:checked ~ .check {
    background-color: #db052c; }
  .radio input[type=radio]:checked ~ .circle {
    border-color: #db052c; }
  .radio input[type=radio]:checked ~ .check {
    transform: scale3d(0.65, 0.65, 1); }

.radio input[type=radio][disabled] ~ .check,
.radio input[type=radio][disabled] ~ .circle {
  opacity: 0.26; }

.radio input[type=radio][disabled] ~ .check {
  background-color: #000000; }

.radio input[type=radio][disabled] ~ .circle {
  border-color: #000000; }

@keyframes rippleOn {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.2; }
  100% {
    opacity: 0; } }

@keyframes rippleOff {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.2; }
  100% {
    opacity: 0; } }

legend {
  margin-bottom: 20px;
  font-size: 21px; }

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.428571429; }

.form-control {
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.428571429; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 36px; }
  input[type="date"].input-sm,
  .input-group-sm input[type="date"],
  input[type="time"].input-sm,
  .input-group-sm
  input[type="time"],
  input[type="datetime-local"].input-sm,
  .input-group-sm
  input[type="datetime-local"],
  input[type="month"].input-sm,
  .input-group-sm
  input[type="month"] {
    line-height: 24px; }
  input[type="date"].input-lg,
  .input-group-lg input[type="date"],
  input[type="time"].input-lg,
  .input-group-lg
  input[type="time"],
  input[type="datetime-local"].input-lg,
  .input-group-lg
  input[type="datetime-local"],
  input[type="month"].input-lg,
  .input-group-lg
  input[type="month"] {
    line-height: 44px; } }

.radio label,
.checkbox label {
  min-height: 20px; }

.form-control-static {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 34px; }

.input-sm .input-sm {
  height: 24px;
  padding: 3px 0;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 0; }

.input-sm select.input-sm {
  height: 24px;
  line-height: 24px; }

.input-sm textarea.input-sm,
.input-sm select[multiple].input-sm {
  height: auto; }

.form-group-sm .form-control {
  height: 24px;
  padding: 3px 0;
  font-size: 11px;
  line-height: 1.5; }

.form-group-sm select.form-control {
  height: 24px;
  line-height: 24px; }

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto; }

.form-group-sm .form-control-static {
  height: 24px;
  min-height: 31px;
  padding: 4px 0;
  font-size: 11px;
  line-height: 1.5; }

.input-lg .input-lg {
  height: 44px;
  padding: 9px 0;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 0; }

.input-lg select.input-lg {
  height: 44px;
  line-height: 44px; }

.input-lg textarea.input-lg,
.input-lg select[multiple].input-lg {
  height: auto; }

.form-group-lg .form-control {
  height: 44px;
  padding: 9px 0;
  font-size: 18px;
  line-height: 1.3333333; }

.form-group-lg select.form-control {
  height: 44px;
  line-height: 44px; }

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto; }

.form-group-lg .form-control-static {
  height: 44px;
  min-height: 38px;
  padding: 10px 0;
  font-size: 18px;
  line-height: 1.3333333; }

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 8px; }

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 28px; }

@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 8px; } }

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 12.9999997px;
    font-size: 18px; } }

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 4px;
    font-size: 11px; } }

.label {
  border-radius: 2px; }
  .label, .label.label-default {
    background-color: #9e9e9e; }
  .label.label-inverse {
    background-color: #3f51b5; }
  .label.label-primary {
    background-color: #db052c; }
  .label.label-success {
    background-color: #4caf50; }
  .label.label-info {
    background-color: #2a2d2e; }
  .label.label-warning {
    background-color: #fbc02d; }
  .label.label-danger {
    background-color: #f44336; }

.form-control,
.form-group .form-control {
  border: 0;
  background-image: linear-gradient(#db052c, #db052c), linear-gradient(#D2D2D2, #D2D2D2);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;
  font-weight: 400; }
  .form-control::-moz-placeholder,
  .form-group .form-control::-moz-placeholder {
    color: #AAAAAA;
    font-weight: 400; }
  .form-control:-ms-input-placeholder,
  .form-group .form-control:-ms-input-placeholder {
    color: #AAAAAA;
    font-weight: 400; }
  .form-control::-webkit-input-placeholder,
  .form-group .form-control::-webkit-input-placeholder {
    color: #AAAAAA;
    font-weight: 400; }
  .form-control[readonly], .form-control[disabled],
  fieldset[disabled] .form-control,
  .form-group .form-control[readonly],
  .form-group .form-control[disabled],
  fieldset[disabled]
  .form-group .form-control {
    background-color: transparent; }
  .form-control[disabled],
  fieldset[disabled] .form-control,
  .form-group .form-control[disabled],
  fieldset[disabled]
  .form-group .form-control {
    background-image: none;
    border-bottom: 1px dotted #D2D2D2; }

.form-group {
  position: relative; }
  .form-group.label-static label.control-label, .form-group.label-placeholder label.control-label, .form-group.label-floating label.control-label {
    position: absolute;
    pointer-events: none;
    transition: 0.3s ease all; }
  .form-group.label-floating label.control-label {
    will-change: left, top, contents; }
  .form-group.label-placeholder:not(.is-empty) label.control-label {
    display: none; }
  .form-group .help-block {
    position: absolute;
    display: none; }
  .form-group.is-focused .form-control {
    outline: none;
    background-image: linear-gradient(#db052c, #db052c), linear-gradient(#D2D2D2, #D2D2D2);
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
    transition-duration: 0.3s; }
    .form-group.is-focused .form-control .material-input:after {
      background-color: #db052c; }
  .form-group.is-focused label,
  .form-group.is-focused label.control-label {
    color: #db052c; }
  .form-group.is-focused.label-placeholder label,
  .form-group.is-focused.label-placeholder label.control-label {
    color: #AAAAAA; }
  .form-group.is-focused .help-block {
    display: block; }
  .form-group.has-warning .form-control {
    box-shadow: none; }
  .form-group.has-warning.is-focused .form-control {
    background-image: linear-gradient(#fbc02d, #fbc02d), linear-gradient(#D2D2D2, #D2D2D2); }
  .form-group.has-warning label.control-label,
  .form-group.has-warning .help-block {
    color: #fbc02d; }
  .form-group.has-error .form-control {
    box-shadow: none; }
  .form-group.has-error.is-focused .form-control {
    background-image: linear-gradient(#f44336, #f44336), linear-gradient(#D2D2D2, #D2D2D2); }
  .form-group.has-error label.control-label,
  .form-group.has-error .help-block {
    color: #f44336; }
  .form-group.has-success .form-control {
    box-shadow: none; }
  .form-group.has-success.is-focused .form-control {
    background-image: linear-gradient(#4caf50, #4caf50), linear-gradient(#D2D2D2, #D2D2D2); }
  .form-group.has-success label.control-label,
  .form-group.has-success .help-block {
    color: #4caf50; }
  .form-group.has-info .form-control {
    box-shadow: none; }
  .form-group.has-info.is-focused .form-control {
    background-image: linear-gradient(#2a2d2e, #2a2d2e), linear-gradient(#D2D2D2, #D2D2D2); }
  .form-group.has-info label.control-label,
  .form-group.has-info .help-block {
    color: #2a2d2e; }
  .form-group textarea {
    resize: none; }
    .form-group textarea ~ .form-control-highlight {
      margin-top: -11px; }
  .form-group select {
    appearance: none; }
    .form-group select ~ .material-input:after {
      display: none; }

.form-control {
  margin-bottom: 7px; }
  .form-control::-moz-placeholder {
    font-size: 14px;
    line-height: 1.428571429;
    color: #AAAAAA;
    font-weight: 400; }
  .form-control:-ms-input-placeholder {
    font-size: 14px;
    line-height: 1.428571429;
    color: #AAAAAA;
    font-weight: 400; }
  .form-control::-webkit-input-placeholder {
    font-size: 14px;
    line-height: 1.428571429;
    color: #AAAAAA;
    font-weight: 400; }

.checkbox label,
.radio label,
label {
  font-size: 14px;
  line-height: 1.428571429;
  color: #AAAAAA;
  font-weight: 400; }

label.control-label {
  font-size: 11px;
  line-height: 1.0714285718;
  color: #AAAAAA;
  font-weight: 400;
  margin: 16px 0 0 0; }

.help-block {
  margin-top: 0;
  font-size: 11px; }

.form-group {
  padding-bottom: 7px;
  margin: 27px 0 0 0; }
  .form-group .form-control {
    margin-bottom: 7px; }
    .form-group .form-control::-moz-placeholder {
      font-size: 14px;
      line-height: 1.428571429;
      color: #AAAAAA;
      font-weight: 400; }
    .form-group .form-control:-ms-input-placeholder {
      font-size: 14px;
      line-height: 1.428571429;
      color: #AAAAAA;
      font-weight: 400; }
    .form-group .form-control::-webkit-input-placeholder {
      font-size: 14px;
      line-height: 1.428571429;
      color: #AAAAAA;
      font-weight: 400; }
  .form-group .checkbox label,
  .form-group .radio label,
  .form-group label {
    font-size: 14px;
    line-height: 1.428571429;
    color: #AAAAAA;
    font-weight: 400; }
  .form-group label.control-label {
    font-size: 11px;
    line-height: 1.0714285718;
    color: #AAAAAA;
    font-weight: 400;
    margin: 16px 0 0 0; }
  .form-group .help-block {
    margin-top: 0;
    font-size: 11px; }
  .form-group.label-floating label.control-label, .form-group.label-placeholder label.control-label {
    top: -7px;
    font-size: 14px;
    line-height: 1.428571429; }
  .form-group.label-static label.control-label, .form-group.label-floating.is-focused label.control-label, .form-group.label-floating:not(.is-empty) label.control-label {
    top: -28px;
    left: 0;
    font-size: 11px;
    line-height: 1.0714285718; }
  .form-group.label-floating input.form-control:-webkit-autofill ~ label.control-label label.control-label {
    top: -28px;
    left: 0;
    font-size: 11px;
    line-height: 1.0714285718; }

.form-group.form-group-sm {
  padding-bottom: 3px;
  margin: 21px 0 0 0; }
  .form-group.form-group-sm .form-control {
    margin-bottom: 3px; }
    .form-group.form-group-sm .form-control::-moz-placeholder {
      font-size: 11px;
      line-height: 1.5;
      color: #AAAAAA;
      font-weight: 400; }
    .form-group.form-group-sm .form-control:-ms-input-placeholder {
      font-size: 11px;
      line-height: 1.5;
      color: #AAAAAA;
      font-weight: 400; }
    .form-group.form-group-sm .form-control::-webkit-input-placeholder {
      font-size: 11px;
      line-height: 1.5;
      color: #AAAAAA;
      font-weight: 400; }
  .form-group.form-group-sm .checkbox label,
  .form-group.form-group-sm .radio label,
  .form-group.form-group-sm label {
    font-size: 11px;
    line-height: 1.5;
    color: #AAAAAA;
    font-weight: 400; }
  .form-group.form-group-sm label.control-label {
    font-size: 9px;
    line-height: 1.125;
    color: #AAAAAA;
    font-weight: 400;
    margin: 16px 0 0 0; }
  .form-group.form-group-sm .help-block {
    margin-top: 0;
    font-size: 9px; }
  .form-group.form-group-sm.label-floating label.control-label, .form-group.form-group-sm.label-placeholder label.control-label {
    top: -11px;
    font-size: 11px;
    line-height: 1.5; }
  .form-group.form-group-sm.label-static label.control-label, .form-group.form-group-sm.label-floating.is-focused label.control-label, .form-group.form-group-sm.label-floating:not(.is-empty) label.control-label {
    top: -25px;
    left: 0;
    font-size: 9px;
    line-height: 1.125; }
  .form-group.form-group-sm.label-floating input.form-control:-webkit-autofill ~ label.control-label label.control-label {
    top: -25px;
    left: 0;
    font-size: 9px;
    line-height: 1.125; }

.form-group.form-group-lg {
  padding-bottom: 9px;
  margin: 30px 0 0 0; }
  .form-group.form-group-lg .form-control {
    margin-bottom: 9px; }
    .form-group.form-group-lg .form-control::-moz-placeholder {
      font-size: 18px;
      line-height: 1.3333333;
      color: #AAAAAA;
      font-weight: 400; }
    .form-group.form-group-lg .form-control:-ms-input-placeholder {
      font-size: 18px;
      line-height: 1.3333333;
      color: #AAAAAA;
      font-weight: 400; }
    .form-group.form-group-lg .form-control::-webkit-input-placeholder {
      font-size: 18px;
      line-height: 1.3333333;
      color: #AAAAAA;
      font-weight: 400; }
  .form-group.form-group-lg .checkbox label,
  .form-group.form-group-lg .radio label,
  .form-group.form-group-lg label {
    font-size: 18px;
    line-height: 1.3333333;
    color: #AAAAAA;
    font-weight: 400; }
  .form-group.form-group-lg label.control-label {
    font-size: 14px;
    line-height: 0.999999975;
    color: #AAAAAA;
    font-weight: 400;
    margin: 16px 0 0 0; }
  .form-group.form-group-lg .help-block {
    margin-top: 0;
    font-size: 14px; }
  .form-group.form-group-lg.label-floating label.control-label, .form-group.form-group-lg.label-placeholder label.control-label {
    top: -5px;
    font-size: 18px;
    line-height: 1.3333333; }
  .form-group.form-group-lg.label-static label.control-label, .form-group.form-group-lg.label-floating.is-focused label.control-label, .form-group.form-group-lg.label-floating:not(.is-empty) label.control-label {
    top: -32px;
    left: 0;
    font-size: 14px;
    line-height: 0.999999975; }
  .form-group.form-group-lg.label-floating input.form-control:-webkit-autofill ~ label.control-label label.control-label {
    top: -32px;
    left: 0;
    font-size: 14px;
    line-height: 0.999999975; }

select.form-control {
  border: 0;
  box-shadow: none;
  border-radius: 0; }
  .form-group.is-focused select.form-control {
    box-shadow: none;
    border-color: #D2D2D2; }
  select.form-control[multiple],
  .form-group.is-focused select.form-control[multiple] {
    height: 85px; }

.input-group-btn .btn {
  margin: 0 0 7px 0; }

.form-group.form-group-sm .input-group-btn .btn {
  margin: 0 0 3px 0; }

.form-group.form-group-lg .input-group-btn .btn {
  margin: 0 0 9px 0; }

.input-group .input-group-btn {
  padding: 0 12px; }

.input-group .input-group-addon {
  border: 0;
  background: transparent;
  padding: 12px 15px 0px; }

.form-control-feedback {
  opacity: 0; }
  .has-success .form-control-feedback {
    color: #4caf50;
    opacity: 1; }
  .has-error .form-control-feedback {
    color: #f44336;
    opacity: 1; }

.pagination > li > a,
.pagination > li > span {
  border: 0;
  border-radius: 30px !important;
  transition: all .3s;
  padding: 0px 11px;
  margin: 0 3px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  color: #999999;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  background: transparent; }
  .pagination > li > a:hover, .pagination > li > a:focus,
  .pagination > li > span:hover,
  .pagination > li > span:focus {
    color: #999999; }

.pagination > .active > a,
.pagination > .active > span {
  color: #999999; }
  .pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover,
  .pagination > .active > span,
  .pagination > .active > span:focus,
  .pagination > .active > span:hover {
    background-color: #db052c;
    border-color: #db052c;
    color: #FFFFFF;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); }

.pagination.pagination-info > .active > a, .pagination.pagination-info > .active > a:focus, .pagination.pagination-info > .active > a:hover,
.pagination.pagination-info > .active > span,
.pagination.pagination-info > .active > span:focus,
.pagination.pagination-info > .active > span:hover {
  background-color: #2a2d2e;
  border-color: #2a2d2e; }

.pagination.pagination-success > .active > a, .pagination.pagination-success > .active > a:focus, .pagination.pagination-success > .active > a:hover,
.pagination.pagination-success > .active > span,
.pagination.pagination-success > .active > span:focus,
.pagination.pagination-success > .active > span:hover {
  background-color: #4caf50;
  border-color: #4caf50; }

.pagination.pagination-warning > .active > a, .pagination.pagination-warning > .active > a:focus, .pagination.pagination-warning > .active > a:hover,
.pagination.pagination-warning > .active > span,
.pagination.pagination-warning > .active > span:focus,
.pagination.pagination-warning > .active > span:hover {
  background-color: #fbc02d;
  border-color: #fbc02d; }

.pagination.pagination-danger > .active > a, .pagination.pagination-danger > .active > a:focus, .pagination.pagination-danger > .active > a:hover,
.pagination.pagination-danger > .active > span,
.pagination.pagination-danger > .active > span:focus,
.pagination.pagination-danger > .active > span:hover {
  background-color: #f44336;
  border-color: #f44336; }

.label {
  border-radius: 3px;
  padding: 5px 12px;
  text-transform: uppercase;
  font-size: 10px; }

.nav-pills > li > a {
  line-height: 24px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  min-width: 100px;
  text-align: center;
  color: #2a2d2e;
  transition: all .3s; }

.nav-pills > li i {
  display: block;
  font-size: 30px;
  padding: 15px 0; }

.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  background-color: #db052c;
  color: #FFFFFF;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

.nav-pills.nav-pills-info > li.active > a, .nav-pills.nav-pills-info > li.active > a:focus, .nav-pills.nav-pills-info > li.active > a:hover {
  background-color: #2a2d2e; }

.nav-pills.nav-pills-success > li.active > a, .nav-pills.nav-pills-success > li.active > a:focus, .nav-pills.nav-pills-success > li.active > a:hover {
  background-color: #4caf50; }

.nav-pills.nav-pills-warning > li.active > a, .nav-pills.nav-pills-warning > li.active > a:focus, .nav-pills.nav-pills-warning > li.active > a:hover {
  background-color: #fbc02d; }

.nav-pills.nav-pills-danger > li.active > a, .nav-pills.nav-pills-danger > li.active > a:focus, .nav-pills.nav-pills-danger > li.active > a:hover {
  background-color: #f44336; }

footer {
  padding: 15px 0; }
  footer ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none; }
    footer ul li {
      display: inline-block; }
      footer ul li a {
        color: inherit;
        padding: 15px;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        border-radius: 3px;
        text-decoration: none;
        position: relative;
        display: block; }
        footer ul li a:hover {
          text-decoration: none; }
  footer .copyright {
    padding: 15px 0; }
    footer .copyright .material-icons {
      font-size: 18px;
      position: relative;
      top: 3px; }

legend {
  border-bottom: 0; }

.navbar {
  background-color: #db052c;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  padding: 10px 0; }
  .navbar .navbar-brand {
    position: relative;
    height: 50px;
    line-height: 30px;
    color: inherit;
    padding: 10px 15px; }
    .navbar .navbar-brand:hover, .navbar .navbar-brand:focus {
      color: inherit;
      background-color: transparent; }
  .navbar .navbar-text {
    color: inherit;
    margin-top: 15px;
    margin-bottom: 15px; }
  .navbar .navbar-nav > li > a {
    color: inherit;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 3px; }
    .navbar .navbar-nav > li > a:hover, .navbar .navbar-nav > li > a:focus {
      color: inherit;
      background-color: transparent; }
    .navbar .navbar-nav > li > a .material-icons,
    .navbar .navbar-nav > li > a .fa {
      font-size: 20px;
      margin-top: -3px;
      margin-right: 3px;
      margin: -3px 4px 0 4px; }
    .navbar .navbar-nav > li > a .fa {
      top: 2px;
      position: relative; }
  .navbar .navbar-nav > li > .dropdown-menu {
    margin-top: -20px; }
  .navbar .navbar-nav > li.open > .dropdown-menu {
    margin-top: 0; }
  .navbar .navbar-nav > .active > a, .navbar .navbar-nav > .active > a:hover, .navbar .navbar-nav > .active > a:focus {
    color: inherit;
    background-color: rgba(255, 255, 255, 0.1); }
  .navbar .navbar-nav > .disabled > a, .navbar .navbar-nav > .disabled > a:hover, .navbar .navbar-nav > .disabled > a:focus {
    color: inherit;
    background-color: transparent;
    opacity: 0.9; }
  .navbar .navbar-toggle {
    border: 0; }
    .navbar .navbar-toggle:hover, .navbar .navbar-toggle:focus {
      background-color: transparent; }
    .navbar .navbar-toggle .icon-bar {
      background-color: inherit;
      border: 1px solid; }
  .navbar .navbar-default .navbar-toggle,
  .navbar .navbar-inverse .navbar-toggle {
    border-color: transparent; }
  .navbar .navbar-collapse,
  .navbar .navbar-form {
    border-top: none;
    box-shadow: none; }
  .navbar .navbar-nav > .open > a, .navbar .navbar-nav > .open > a:hover, .navbar .navbar-nav > .open > a:focus {
    background-color: transparent;
    color: inherit; }
  @media (max-width: 767px) {
    .navbar .navbar-nav .navbar-text {
      color: inherit;
      margin-top: 15px;
      margin-bottom: 15px; }
    .navbar .navbar-nav .open .dropdown-menu > .dropdown-header {
      border: 0;
      color: inherit; }
    .navbar .navbar-nav .open .dropdown-menu .divider {
      border-bottom: 1px solid;
      opacity: 0.08; }
    .navbar .navbar-nav .open .dropdown-menu > li > a {
      color: inherit; }
      .navbar .navbar-nav .open .dropdown-menu > li > a:hover, .navbar .navbar-nav .open .dropdown-menu > li > a:focus {
        color: inherit;
        background-color: transparent; }
    .navbar .navbar-nav .open .dropdown-menu > .active > a, .navbar .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
      color: inherit;
      background-color: transparent; }
    .navbar .navbar-nav .open .dropdown-menu > .disabled > a, .navbar .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar .navbar-nav .open .dropdown-menu > .disabled > a:focus {
      color: inherit;
      background-color: transparent; } }
  .navbar .navbar-link {
    color: inherit; }
    .navbar .navbar-link:hover {
      color: inherit; }
  .navbar .btn {
    margin-top: 0;
    margin-bottom: 0; }
  .navbar .btn-link {
    color: inherit; }
    .navbar .btn-link:hover, .navbar .btn-link:focus {
      color: inherit; }
    .navbar .btn-link[disabled]:hover, .navbar .btn-link[disabled]:focus,
    fieldset[disabled] .navbar .btn-link:hover,
    fieldset[disabled] .navbar .btn-link:focus {
      color: inherit; }
  .navbar .navbar-form {
    margin-top: 16px; }
    .navbar .navbar-form .form-group {
      margin: 0;
      padding: 0; }
      .navbar .navbar-form .form-group .material-input:before,
      .navbar .navbar-form .form-group.is-focused .material-input:after {
        background-color: inherit; }
    .navbar .navbar-form .form-group .form-control,
    .navbar .navbar-form .form-control {
      border-color: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
      height: 28px;
      font-size: 14px;
      line-height: 1.428571429; }
  .navbar, .navbar.navbar-default {
    background-color: #db052c;
    color: #ffffff; }
    .navbar .navbar-form .form-group input.form-control::-moz-placeholder,
    .navbar .navbar-form input.form-control::-moz-placeholder, .navbar.navbar-default .navbar-form .form-group input.form-control::-moz-placeholder,
    .navbar.navbar-default .navbar-form input.form-control::-moz-placeholder {
      color: #ffffff; }
    .navbar .navbar-form .form-group input.form-control:-ms-input-placeholder,
    .navbar .navbar-form input.form-control:-ms-input-placeholder, .navbar.navbar-default .navbar-form .form-group input.form-control:-ms-input-placeholder,
    .navbar.navbar-default .navbar-form input.form-control:-ms-input-placeholder {
      color: #ffffff; }
    .navbar .navbar-form .form-group input.form-control::-webkit-input-placeholder,
    .navbar .navbar-form input.form-control::-webkit-input-placeholder, .navbar.navbar-default .navbar-form .form-group input.form-control::-webkit-input-placeholder,
    .navbar.navbar-default .navbar-form input.form-control::-webkit-input-placeholder {
      color: #ffffff; }
    .navbar .dropdown-menu, .navbar.navbar-default .dropdown-menu {
      border-radius: 3px !important; }
      .navbar .dropdown-menu li > a:hover, .navbar .dropdown-menu li > a:focus, .navbar.navbar-default .dropdown-menu li > a:hover, .navbar.navbar-default .dropdown-menu li > a:focus {
        color: #FFFFFF;
        background-color: #db052c; }
      .navbar .dropdown-menu .active > a, .navbar.navbar-default .dropdown-menu .active > a {
        background-color: #db052c;
        color: #ffffff; }
        .navbar .dropdown-menu .active > a:hover, .navbar .dropdown-menu .active > a:focus, .navbar.navbar-default .dropdown-menu .active > a:hover, .navbar.navbar-default .dropdown-menu .active > a:focus {
          color: #ffffff; }
  .navbar.navbar-inverse {
    background-color: #3f51b5;
    color: contrast-color(#3f51b5, #000000, #ffffff); }
    .navbar.navbar-inverse .navbar-form .form-group input.form-control::-moz-placeholder,
    .navbar.navbar-inverse .navbar-form input.form-control::-moz-placeholder {
      color: contrast-color(#3f51b5, #000000, #ffffff); }
    .navbar.navbar-inverse .navbar-form .form-group input.form-control:-ms-input-placeholder,
    .navbar.navbar-inverse .navbar-form input.form-control:-ms-input-placeholder {
      color: contrast-color(#3f51b5, #000000, #ffffff); }
    .navbar.navbar-inverse .navbar-form .form-group input.form-control::-webkit-input-placeholder,
    .navbar.navbar-inverse .navbar-form input.form-control::-webkit-input-placeholder {
      color: contrast-color(#3f51b5, #000000, #ffffff); }
    .navbar.navbar-inverse .dropdown-menu {
      border-radius: 3px !important; }
      .navbar.navbar-inverse .dropdown-menu li > a:hover, .navbar.navbar-inverse .dropdown-menu li > a:focus {
        color: #FFFFFF;
        background-color: #3f51b5; }
      .navbar.navbar-inverse .dropdown-menu .active > a {
        background-color: #3f51b5;
        color: contrast-color(#3f51b5, #000000, #ffffff); }
        .navbar.navbar-inverse .dropdown-menu .active > a:hover, .navbar.navbar-inverse .dropdown-menu .active > a:focus {
          color: contrast-color(#3f51b5, #000000, #ffffff); }
  .navbar.navbar-primary {
    background-color: #db052c;
    color: #ffffff; }
    .navbar.navbar-primary .navbar-form .form-group input.form-control::-moz-placeholder,
    .navbar.navbar-primary .navbar-form input.form-control::-moz-placeholder {
      color: #ffffff; }
    .navbar.navbar-primary .navbar-form .form-group input.form-control:-ms-input-placeholder,
    .navbar.navbar-primary .navbar-form input.form-control:-ms-input-placeholder {
      color: #ffffff; }
    .navbar.navbar-primary .navbar-form .form-group input.form-control::-webkit-input-placeholder,
    .navbar.navbar-primary .navbar-form input.form-control::-webkit-input-placeholder {
      color: #ffffff; }
    .navbar.navbar-primary .dropdown-menu {
      border-radius: 3px !important; }
      .navbar.navbar-primary .dropdown-menu li > a:hover, .navbar.navbar-primary .dropdown-menu li > a:focus {
        color: #FFFFFF;
        background-color: #db052c; }
      .navbar.navbar-primary .dropdown-menu .active > a {
        background-color: #db052c;
        color: #ffffff; }
        .navbar.navbar-primary .dropdown-menu .active > a:hover, .navbar.navbar-primary .dropdown-menu .active > a:focus {
          color: #ffffff; }
  .navbar.navbar-success {
    background-color: #4caf50;
    color: #ffffff; }
    .navbar.navbar-success .navbar-form .form-group input.form-control::-moz-placeholder,
    .navbar.navbar-success .navbar-form input.form-control::-moz-placeholder {
      color: #ffffff; }
    .navbar.navbar-success .navbar-form .form-group input.form-control:-ms-input-placeholder,
    .navbar.navbar-success .navbar-form input.form-control:-ms-input-placeholder {
      color: #ffffff; }
    .navbar.navbar-success .navbar-form .form-group input.form-control::-webkit-input-placeholder,
    .navbar.navbar-success .navbar-form input.form-control::-webkit-input-placeholder {
      color: #ffffff; }
    .navbar.navbar-success .dropdown-menu {
      border-radius: 3px !important; }
      .navbar.navbar-success .dropdown-menu li > a:hover, .navbar.navbar-success .dropdown-menu li > a:focus {
        color: #FFFFFF;
        background-color: #4caf50; }
      .navbar.navbar-success .dropdown-menu .active > a {
        background-color: #4caf50;
        color: #ffffff; }
        .navbar.navbar-success .dropdown-menu .active > a:hover, .navbar.navbar-success .dropdown-menu .active > a:focus {
          color: #ffffff; }
  .navbar.navbar-info {
    background-color: #2a2d2e;
    color: #ffffff; }
    .navbar.navbar-info .navbar-form .form-group input.form-control::-moz-placeholder,
    .navbar.navbar-info .navbar-form input.form-control::-moz-placeholder {
      color: #ffffff; }
    .navbar.navbar-info .navbar-form .form-group input.form-control:-ms-input-placeholder,
    .navbar.navbar-info .navbar-form input.form-control:-ms-input-placeholder {
      color: #ffffff; }
    .navbar.navbar-info .navbar-form .form-group input.form-control::-webkit-input-placeholder,
    .navbar.navbar-info .navbar-form input.form-control::-webkit-input-placeholder {
      color: #ffffff; }
    .navbar.navbar-info .dropdown-menu {
      border-radius: 3px !important; }
      .navbar.navbar-info .dropdown-menu li > a:hover, .navbar.navbar-info .dropdown-menu li > a:focus {
        color: #FFFFFF;
        background-color: #2a2d2e; }
      .navbar.navbar-info .dropdown-menu .active > a {
        background-color: #2a2d2e;
        color: #ffffff; }
        .navbar.navbar-info .dropdown-menu .active > a:hover, .navbar.navbar-info .dropdown-menu .active > a:focus {
          color: #ffffff; }
  .navbar.navbar-warning {
    background-color: #fbc02d;
    color: #ffffff; }
    .navbar.navbar-warning .navbar-form .form-group input.form-control::-moz-placeholder,
    .navbar.navbar-warning .navbar-form input.form-control::-moz-placeholder {
      color: #ffffff; }
    .navbar.navbar-warning .navbar-form .form-group input.form-control:-ms-input-placeholder,
    .navbar.navbar-warning .navbar-form input.form-control:-ms-input-placeholder {
      color: #ffffff; }
    .navbar.navbar-warning .navbar-form .form-group input.form-control::-webkit-input-placeholder,
    .navbar.navbar-warning .navbar-form input.form-control::-webkit-input-placeholder {
      color: #ffffff; }
    .navbar.navbar-warning .dropdown-menu {
      border-radius: 3px !important; }
      .navbar.navbar-warning .dropdown-menu li > a:hover, .navbar.navbar-warning .dropdown-menu li > a:focus {
        color: #FFFFFF;
        background-color: #fbc02d; }
      .navbar.navbar-warning .dropdown-menu .active > a {
        background-color: #fbc02d;
        color: #ffffff; }
        .navbar.navbar-warning .dropdown-menu .active > a:hover, .navbar.navbar-warning .dropdown-menu .active > a:focus {
          color: #ffffff; }
  .navbar.navbar-danger {
    background-color: #f44336;
    color: #ffffff; }
    .navbar.navbar-danger .navbar-form .form-group input.form-control::-moz-placeholder,
    .navbar.navbar-danger .navbar-form input.form-control::-moz-placeholder {
      color: #ffffff; }
    .navbar.navbar-danger .navbar-form .form-group input.form-control:-ms-input-placeholder,
    .navbar.navbar-danger .navbar-form input.form-control:-ms-input-placeholder {
      color: #ffffff; }
    .navbar.navbar-danger .navbar-form .form-group input.form-control::-webkit-input-placeholder,
    .navbar.navbar-danger .navbar-form input.form-control::-webkit-input-placeholder {
      color: #ffffff; }
    .navbar.navbar-danger .dropdown-menu {
      border-radius: 3px !important; }
      .navbar.navbar-danger .dropdown-menu li > a:hover, .navbar.navbar-danger .dropdown-menu li > a:focus {
        color: #FFFFFF;
        background-color: #f44336; }
      .navbar.navbar-danger .dropdown-menu .active > a {
        background-color: #f44336;
        color: #ffffff; }
        .navbar.navbar-danger .dropdown-menu .active > a:hover, .navbar.navbar-danger .dropdown-menu .active > a:focus {
          color: #ffffff; }
  .navbar-inverse {
    background-color: #3f51b5; }
  .navbar.navbar-transparent {
    background-color: transparent;
    box-shadow: none; }
  .navbar-fixed-top {
    border-radius: 0; }
  @media (max-width: 1199px) {
    .navbar .navbar-brand {
      height: 50px;
      padding: 10px 15px; }
    .navbar .navbar-form {
      margin-top: 10px; }
    .navbar .navbar-nav > li > a {
      padding-top: 15px;
      padding-bottom: 15px; } }
  .navbar .alert {
    border-radius: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 85px;
    width: 100%;
    z-index: 3;
    transition: all 0.3s; }

.dropdown-menu {
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  margin-top: -20px;
  opacity: 0;
  visibility: hidden;
  display: block; }
  .open .dropdown-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 1px; }
  .dropdown-menu .divider {
    background-color: rgba(0, 0, 0, 0.12); }
  .dropdown-menu li > a {
    font-size: 13px;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 2px;
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear; }
    .dropdown-menu li > a:hover, .dropdown-menu li > a:focus {
      box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }
  .dropdown-menu li {
    position: relative; }
    .dropdown-menu li a:hover,
    .dropdown-menu li a:focus,
    .dropdown-menu li a:active {
      background-color: #db052c;
      color: #FFFFFF; }
  .dropdown-menu .divider {
    margin: 5px 0; }

.alert {
  border: 0;
  border-radius: 0;
  padding: 20px 15px;
  line-height: 20px; }
  .alert b {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px; }
  .alert, .alert.alert-default {
    background-color: white;
    color: #ffffff; }
    .alert a, .alert .alert-link, .alert.alert-default a, .alert.alert-default .alert-link {
      color: #ffffff; }
  .alert.alert-inverse {
    background-color: #4558be;
    color: contrast-color(#3f51b5, #000000, #ffffff); }
    .alert.alert-inverse a, .alert.alert-inverse .alert-link {
      color: contrast-color(#3f51b5, #000000, #ffffff); }
  .alert.alert-primary {
    background-color: #ea052f;
    color: #ffffff; }
    .alert.alert-primary a, .alert.alert-primary .alert-link {
      color: #ffffff; }
  .alert.alert-success {
    background-color: #55b559;
    color: #ffffff; }
    .alert.alert-success a, .alert.alert-success .alert-link {
      color: #ffffff; }
  .alert.alert-info {
    background-color: #313536;
    color: #ffffff; }
    .alert.alert-info a, .alert.alert-info .alert-link {
      color: #ffffff; }
  .alert.alert-warning {
    background-color: #fbc53c;
    color: #ffffff; }
    .alert.alert-warning a, .alert.alert-warning .alert-link {
      color: #ffffff; }
  .alert.alert-danger {
    background-color: #f55145;
    color: #ffffff; }
    .alert.alert-danger a, .alert.alert-danger .alert-link {
      color: #ffffff; }
  .alert-info, .alert-danger, .alert-warning, .alert-success {
    color: #ffffff; }
  .alert-default a, .alert-default .alert-link {
    color: rgba(0,0,0, 0.87); }
  .alert .alert-icon {
    display: block;
    float: left;
    margin-right: 15px; }
    .alert .alert-icon i {
      margin-top: -7px;
      top: 5px;
      position: relative; }

.progress {
  height: 4px;
  border-radius: 0;
  box-shadow: none;
  background: #DDDDDD; }
  .progress .progress-bar {
    box-shadow: none; }
    .progress .progress-bar, .progress .progress-bar.progress-bar-default {
      background-color: #db052c; }
    .progress .progress-bar.progress-bar-inverse {
      background-color: #3f51b5; }
    .progress .progress-bar.progress-bar-primary {
      background-color: #db052c; }
    .progress .progress-bar.progress-bar-success {
      background-color: #4caf50; }
    .progress .progress-bar.progress-bar-info {
      background-color: #2a2d2e; }
    .progress .progress-bar.progress-bar-warning {
      background-color: #fbc02d; }
    .progress .progress-bar.progress-bar-danger {
      background-color: #f44336; }
  .progress.progress-line-primary {
    background: rgba(219, 5, 44, 0.2); }
  .progress.progress-line-info {
    background: rgba(42, 45, 46, 0.2); }
  .progress.progress-line-success {
    background: rgba(76, 175, 80, 0.2); }
  .progress.progress-line-warning {
    background: rgba(251, 192, 45, 0.2); }
  .progress.progress-line-danger {
    background: rgba(244, 67, 54, 0.2); }

h1, .h1 {
  font-size: 3.8em;
  line-height: 1.15em; }

h2, .h2 {
  font-size: 2.6em; }

h3, .h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px; }

h4, .h4 {
  font-size: 1.3em;
  line-height: 1.4em; }

h5, .h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px; }

h6, .h6 {
  font-size: 1em;
  text-transform: uppercase; }

.text-warning {
  color: #fbc02d; }

.text-primary {
  color: #db052c; }

.text-danger {
  color: #f44336; }

.text-success {
  color: #4caf50; }

.text-info {
  color: #2a2d2e; }

.nav-tabs {
  background: #db052c;
  border: 0;
  border-radius: 3px;
  padding: 0 15px; }
  .nav-tabs > li > a {
    color: #FFFFFF;
    border: 0;
    margin: 0;
    border-radius: 3px;
    line-height: 24px;
    text-transform: uppercase;
    font-size: 12px; }
    .nav-tabs > li > a:hover {
      background-color: transparent;
      border: 0; }
  .nav-tabs > li > a,
  .nav-tabs > li > a:hover,
  .nav-tabs > li > a:focus {
    background-color: transparent;
    border: 0 !important;
    color: #FFFFFF !important;
    font-weight: 500; }
  .nav-tabs > li.disabled > a,
  .nav-tabs > li.disabled > a:hover {
    color: rgba(255, 255, 255, 0.5); }
  .nav-tabs > li .material-icons {
    margin: -1px 5px 0 0; }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    background-color: rgba(255, 255, 255, 0.2);
    transition: background-color .1s .2s; }

.popover, .tooltip-inner {
  color: #2a2d2e;
  line-height: 1.5em;
  background: #FFFFFF;
  border: none;
  border-radius: 3px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }

.popover {
  padding: 0;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }
  .popover.left > .arrow, .popover.right > .arrow, .popover.top > .arrow, .popover.bottom > .arrow {
    border: none; }

.popover-title {
  background-color: #FFFFFF;
  border: none;
  padding: 15px 15px 5px;
  font-size: 1.3em; }

.popover-content {
  padding: 10px 15px 15px;
  line-height: 1.4; }

.tooltip, .tooltip.in {
  opacity: 1; }

.tooltip.left .tooltip-arrow {
  border-left-color: #FFFFFF; }

.tooltip.right .tooltip-arrow {
  border-right-color: #FFFFFF; }

.tooltip.top .tooltip-arrow {
  border-top-color: #FFFFFF; }

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #FFFFFF; }

.tooltip-inner {
  padding: 10px 15px;
  min-width: 130px; }

.carousel .carousel-control {
  width: 50%; }
  .carousel .carousel-control.left, .carousel .carousel-control.right {
    background-image: none; }
  .carousel .carousel-control .material-icons,
  .carousel .carousel-control .fa {
    display: none; }

.carousel .left {
  cursor: url("../img/arrow-left.png"), url("../img/arrow-left.cur"), default !important; }

.carousel .right {
  cursor: url("../img/arrow-right.png"), url("../img/arrow-right.cur"), default !important; }

.carousel .carousel-indicators {
  bottom: 5px; }
  .carousel .carousel-indicators li,
  .carousel .carousel-indicators .active {
    margin: 11px 10px; }
  .carousel .carousel-indicators li {
    background: #FFFFFF;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 2px; }
  .carousel .carousel-indicators .active {
    margin-top: 10px;
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -o-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); }

.carousel .carousel-caption {
  padding-bottom: 45px; }
  .carousel .carousel-caption .material-icons {
    position: relative;
    top: 5px; }

.card {
  display: inline-block;
  position: relative;
  width: 100%;
  border-radius: 3px;
  color: rgba(0,0,0, 0.87);
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .card .card-height-indicator {
    margin-top: 100%; }
  .card .card-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .card .card-image {
    height: 60%;
    position: relative;
    overflow: hidden; }
    .card .card-image img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      pointer-events: none; }
    .card .card-image .card-image-headline {
      position: absolute;
      bottom: 16px;
      left: 18px;
      color: #fff;
      font-size: 2em; }
  .card .content {
    padding: 15px; }
  .card .card-body {
    height: 30%;
    padding: 18px; }
  .card .card-footer {
    height: 10%;
    padding: 18px; }
    .card .card-footer button, .card .card-footer a {
      margin: 0 !important;
      position: relative;
      bottom: 25px;
      width: auto; }
      .card .card-footer button:first-child, .card .card-footer a:first-child {
        left: -15px; }
  .card .header {
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    margin: 15px;
    border-radius: 3px;
    padding: 15px 0;
    background-color: #FFFFFF; }
  .card .header-primary {
    background: linear-gradient(60deg, #ab47bc, #7b1fa2); }
  .card .header-info {
    background: linear-gradient(60deg, #fa1942, #db052c); }
  .card .header-success {
    background: linear-gradient(60deg, #66bb6a, #388e3c); }
  .card .header-warning {
    background: linear-gradient(60deg, #fdd835, #fbc02d); }
  .card .header-danger {
    background: linear-gradient(60deg, #ef5350, #d32f2f); }
  .card [class*="header-"] {
    color: #FFFFFF; }

.card-raised {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

.card-signup .header {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  margin-left: 20px;
  margin-right: 20px;
  margin-top: -40px;
  padding: 20px 0; }

.card-signup .text-divider {
  margin-top: 30px;
  margin-bottom: 0px;
  text-align: center; }

.card-signup .content {
  padding: 0px 30px 0px 10px; }

.card-signup .checkbox {
  margin-top: 20px; }
  .card-signup .checkbox label {
    margin-left: 17px; }
  .card-signup .checkbox .checkbox-material {
    padding-right: 12px; }

.card-signup .social-line {
  margin-top: 15px;
  text-align: center; }
  .card-signup .social-line .btn {
    color: #FFFFFF;
    margin-left: 5px;
    margin-right: 5px; }

.card-nav-tabs {
  margin-top: 45px; }
  .card-nav-tabs .header {
    margin-top: -30px; }
  .card-nav-tabs .nav-tabs {
    background: transparent; }

.card-plain {
  background: transparent;
  box-shadow: none; }
  .card-plain .header {
    margin-left: 0;
    margin-right: 0; }
  .card-plain .content {
    padding-left: 0;
    padding-right: 0; }

.modal-content {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
  border-radius: 3px;
  border: none; }
  .modal-content .modal-header {
    border-bottom: none;
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 0;
    padding-left: 24px; }
  .modal-content .modal-body {
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 24px; }
  .modal-content .modal-footer {
    border-top: none;
    padding: 7px; }
    .modal-content .modal-footer button {
      margin: 0;
      padding-left: 16px;
      padding-right: 16px;
      width: auto; }
      .modal-content .modal-footer button.pull-left {
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: -5px; }
    .modal-content .modal-footer button + button {
      margin-bottom: 16px; }
  .modal-content .modal-body + .modal-footer {
    padding-top: 0; }

.modal-backdrop {
  background: rgba(0, 0, 0, 0.3); }

.modal .modal-dialog {
  margin-top: 100px; }

.modal .modal-header .close {
  color: #2a2d2e; }
  .modal .modal-header .close:hover, .modal .modal-header .close:focus {
    opacity: 1;
    color: #2a2d2e; }

.panel {
  border-radius: 2px;
  border: 0;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12); }
  .panel > .panel-heading,
  .panel.panel-default > .panel-heading {
    background-color: #eeeeee; }
  .panel.panel-inverse > .panel-heading {
    background-color: #3f51b5; }
  .panel.panel-primary > .panel-heading {
    background-color: #db052c; }
  .panel.panel-success > .panel-heading {
    background-color: #4caf50; }
  .panel.panel-info > .panel-heading {
    background-color: #2a2d2e; }
  .panel.panel-warning > .panel-heading {
    background-color: #fbc02d; }
  .panel.panel-danger > .panel-heading {
    background-color: #f44336; }

[class*="panel-"] > .panel-heading {
  color: #ffffff;
  border: 0; }

.panel-default > .panel-heading, .panel:not([class*="panel-"]) > .panel-heading {
  color: rgba(0,0,0, 0.87); }

.panel-footer {
  background-color: #eeeeee; }

hr.on-dark {
  color: #1a1a1a; }

hr.on-light {
  color: white; }

@media (-webkit-min-device-pixel-ratio: 0.75), (min--moz-device-pixel-ratio: 0.75), (-o-device-pixel-ratio: 3 / 4), (min-device-pixel-ratio: 0.75), (min-resolution: 0.75dppx), (min-resolution: 120dpi) {
  hr {
    height: 0.75px; } }

@media (-webkit-min-device-pixel-ratio: 1), (min--moz-device-pixel-ratio: 1), (-o-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx), (min-resolution: 160dpi) {
  hr {
    height: 1px; } }

@media (-webkit-min-device-pixel-ratio: 1.33), (min--moz-device-pixel-ratio: 1.33), (-o-device-pixel-ratio: 133 / 100), (min-device-pixel-ratio: 1.33), (min-resolution: 1.33dppx), (min-resolution: 213dpi) {
  hr {
    height: 1.333px; } }

@media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-device-pixel-ratio: 3 / 2), (min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx), (min-resolution: 240dpi) {
  hr {
    height: 1.5px; } }

@media (-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-device-pixel-ratio: 2 / 1), (min-device-pixel-ratio: 2), (min-resolution: 2dppx), (min-resolution: 380dpi) {
  hr {
    height: 2px; } }

@media (-webkit-min-device-pixel-ratio: 3), (min--moz-device-pixel-ratio: 3), (-o-device-pixel-ratio: 3 / 1), (min-device-pixel-ratio: 3), (min-resolution: 3dppx), (min-resolution: 480dpi) {
  hr {
    height: 3px; } }

@media (-webkit-min-device-pixel-ratio: 4), (min--moz-device-pixel-ratio: 4), (-o-device-pixel-ratio: 4 / 1), (min-device-pixel-ratio: 3), (min-resolution: 4dppx), (min-resolution: 640dpi) {
  hr {
    height: 4px; } }

.img-thumbnail {
  border-radius: 16px; }

.img-raised {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent; }
  *:focus {
    outline: 0; }

a:focus, a:active,
button:active, button:focus, button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important; }

.section {
  padding: 70px 0; }

.section-navbars {
  padding-bottom: 0; }

.section-full-screen {
  height: 100vh; }

.section-signup {
  padding-top: 20vh; }

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -ms-touch-action: none;
  user-select: none;
  box-sizing: border-box; }

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative; }

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0; }

.noUi-handle {
  position: relative;
  z-index: 1;
  box-sizing: border-box; }

.noUi-stacking .noUi-handle {
  z-index: 10; }

.noUi-state-tap .noUi-origin {
  transition: left 0.3s, top 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

.noUi-horizontal {
  height: 10px; }

.noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: -10px;
  top: -6px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid;
  background: #FFFFFF;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.noUi-vertical .noUi-handle {
  margin-left: 5px;
  cursor: ns-resize; }

.noUi-horizontal.noUi-extended {
  padding: 0 15px; }

.noUi-horizontal.noUi-extended .noUi-origin {
  right: -15px; }

.noUi-background {
  height: 2px;
  margin: 20px 0; }

.noUi-origin {
  margin: 0;
  border-radius: 0;
  height: 2px;
  background: #c8c8c8; }
  .noUi-origin[style^="left: 0"] .noUi-handle {
    background-color: #fff;
    border: 2px solid #c8c8c8; }
    .noUi-origin[style^="left: 0"] .noUi-handle.noUi-active {
      border-width: 1px; }

.noUi-target {
  border-radius: 3px; }

.noUi-horizontal {
  height: 2px;
  margin: 15px 0; }

.noUi-vertical {
  height: 100%;
  width: 2px;
  margin: 0 15px;
  display: inline-block; }

.noUi-handle.noUi-active {
  transform: scale3d(2, 2, 1); }

[disabled].noUi-slider {
  opacity: 0.5; }

[disabled] .noUi-handle {
  cursor: not-allowed; }

.slider {
  background: #c8c8c8; }

.slider.noUi-connect {
  background-color: #db052c; }

.slider .noUi-handle {
  border-color: #db052c; }

.slider.slider-info .noUi-connect, .slider.slider-info.noUi-connect {
  background-color: #2a2d2e; }

.slider.slider-info .noUi-handle {
  border-color: #2a2d2e; }

.slider.slider-success .noUi-connect, .slider.slider-success.noUi-connect {
  background-color: #4caf50; }

.slider.slider-success .noUi-handle {
  border-color: #4caf50; }

.slider.slider-warning .noUi-connect, .slider.slider-warning.noUi-connect {
  background-color: #fbc02d; }

.slider.slider-warning .noUi-handle {
  border-color: #fbc02d; }

.slider.slider-danger .noUi-connect, .slider.slider-danger.noUi-connect {
  background-color: #f44336; }

.slider.slider-danger .noUi-handle {
  border-color: #f44336; }

.dropdownjs::after {
  right: 5px;
  top: 3px;
  font-size: 25px;
  position: absolute;
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  content: "\e5c5";
  pointer-events: none;
  color: #757575; }

/*!
 * Datepicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */
/*
 *
 *   SCSS by Creative Tim
 *   http://www.creative-tim.com
 *
 */
.datepicker {
  top: 0;
  left: 0;
  padding: 4px;
  margin-top: 1px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }
  .datepicker > div {
    display: none; }
  .datepicker table {
    width: 100%;
    margin: 0; }
  .datepicker td,
  .datepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px; }
  .datepicker td {
    text-align: center; }
    .datepicker td p {
      font-size: 1em;
      font-weight: 400;
      border-radius: 50%;
      height: 29px;
      line-height: 29px;
      margin: 3px 0 8px;
      width: 29px; }
    .datepicker td :hover {
      cursor: pointer; }
  .datepicker th {
    font-weight: 500; }
    .datepicker th.switch-datepicker {
      font-size: 1em; }
  .datepicker .prev p,
  .datepicker .next p {
    font-size: 1.825em; }
  .datepicker p:hover {
    background: #eeeeee; }
  .datepicker .day.disabled {
    color: #eeeeee; }
  .datepicker td.old,
  .datepicker td.new {
    color: #999999;
    border-top: 0; }
  .datepicker td.active p,
  .datepicker td.active:hover p {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    color: #ffffff;
    background-color: #db052c; }
  .datepicker td.primary p,
  .datepicker td.primary:hover p {
    background-color: #db052c; }
  .datepicker td.info p,
  .datepicker td.info:hover p {
    background-color: #2a2d2e; }
  .datepicker td.success p,
  .datepicker td.success:hover p {
    background-color: #4caf50; }
  .datepicker td.warning p,
  .datepicker td.warning:hover p {
    background-color: #fbc02d; }
  .datepicker td.danger p,
  .datepicker td.danger:hover p {
    background-color: #f44336; }
  .datepicker span {
    display: block;
    width: 55px;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 2px;
    cursor: pointer;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px; }
    .datepicker span.old {
      color: #999999; }
  .datepicker span.active, .datepicker span.active:focus, .datepicker span.active:hover, .datepicker span.active:active {
    background-color: #db052c;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); }
  .datepicker span.active {
    color: #FFFFFF; }
  .datepicker span:hover {
    background-color: #EEEEEE; }
  .datepicker span.primary,
  .datepicker span.primary:hover {
    background-color: #db052c; }
  .datepicker span.info,
  .datepicker span.info:hover {
    background-color: #2a2d2e; }
  .datepicker span.success,
  .datepicker span.success:hover {
    background-color: #4caf50; }
  .datepicker span.warning,
  .datepicker span.warning:hover {
    background-color: #fbc02d; }
  .datepicker span.danger,
  .datepicker span.danger:hover {
    background-color: #f44336; }
  .datepicker th.switch-datepicker {
    width: 145px; }
  .datepicker th.next,
  .datepicker th.prev {
    font-size: 21px; }
  .datepicker thead tr:first-child th {
    cursor: pointer; }
  .datepicker thead tr:first-child th:hover {
    background: #eeeeee; }
  .datepicker.dropdown-menu {
    border-radius: 3px;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    margin-top: -20px;
    opacity: 0;
    visibility: hidden; }
  .datepicker.dropdown-menu.open {
    opacity: 1;
    visibility: visible;
    margin-top: 1px; }
  .datepicker .table-condensed > tbody > tr > td {
    padding: 2px; }
  .datepicker .table-condensed > thead > tr > th {
    padding: 0; }

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  display: block;
  cursor: pointer;
  width: 16px;
  height: 16px; }

.datepicker-months thead {
  padding: 0 0 3px;
  display: block; }

.withripple {
  position: relative; }

.ripple-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: inherit;
  pointer-events: none; }

.ripple {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  border-radius: 100%;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  transform: scale(1);
  transform-origin: 50%;
  opacity: 0;
  pointer-events: none; }

.ripple.ripple-on {
  transition: opacity 0.15s ease-in 0s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  opacity: 0.1; }

.ripple.ripple-out {
  transition: opacity 0.1s linear 0s !important;
  opacity: 0; }

.wrapper > .header {
  min-height: 300px;
  background-position: center center;
  background-size: cover; }

.main {
  background: #FFFFFF;
  position: relative;
  z-index: 3; }

.main-raised {
  margin: -60px 30px 0px;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

.title {
  font-weight: 500;
  color: #3C4858; }

h2.title {
  margin-bottom: 30px; }

.description {
  color: #999999; }

.header-filter {
  position: relative; }
  .header-filter:after {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.4); }
  .header-filter .container {
    z-index: 2;
    position: relative; }

.gallery .image img {
  width: 100%; }

.features {
  padding: 80px 0 0; }
  .features .feature {
    max-width: 360px;
    margin: 0 auto; }
    .features .feature > i {
      font-size: 60px; }
    .features .feature h4 {
      color: #3C4858;
      margin: 30px 0 15px; }
    .features .feature.feature-primary > i {
      color: #db052c; }
    .features .feature.feature-info > i {
      color: #2a2d2e; }
    .features .feature.feature-success > i {
      color: #4caf50; }
    .features .feature.feature-warning > i {
      color: #fbc02d; }
    .features .feature.feature-danger > i {
      color: #f44336; }

.team {
  margin-top: 80px; }
  .team .team-player .title {
    margin: 30px auto; }
  .team .team-player img {
    max-width: 170px; }

.nav-align-center {
  text-align: center; }
  .nav-align-center .nav-pills {
    display: inline-block; }

.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1031; }

.index-page .wrapper > .header {
  height: 90vh; }

.index-page .brand {
  margin-top: 30vh;
  color: #FFFFFF;
  text-align: center; }
  .index-page .brand h1 {
    font-size: 4.8em;
    font-weight: 600; }
  .index-page .brand h3 {
    font-size: 1.5em;
    text-transform: uppercase;
    max-width: 400px;
    margin: 10px auto 0; }

.index-page .section-basic {
  padding-top: 15px; }

.index-page .header-filter:after {
  background: rgba(101, 47, 142, 0.64);
  background: linear-gradient(45deg, rgba(101, 47, 142, 0.88) 0%, rgba(125, 46, 185, 0.45) 100%);
  background: -moz-linear-gradient(135deg, rgba(101, 47, 142, 0.88) 0%, rgba(125, 46, 185, 0.45) 100%);
  background: -webkit-linear-gradient(135deg, rgba(101, 47, 142, 0.88) 0%, rgba(125, 46, 185, 0.45) 100%); }

.landing-page .header {
  height: 100vh; }
  .landing-page .header .container {
    padding-top: 26vh;
    color: #FFFFFF; }
  .landing-page .header .share {
    margin-top: 150px; }
  .landing-page .header h1 {
    font-weight: 600; }
  .landing-page .header .title {
    color: #FFFFFF; }

.landing-page .wrapper {
  background: #CCCCCC; }

.profile-page .header {
  height: 380px;
  background-position: top center; }

.profile-page .profile {
  text-align: center; }
  .profile-page .profile img {
    max-width: 160px;
    margin: -80px auto 0; }

.profile-page .description {
  margin: 30px auto 0;
  max-width: 600px; }

.profile-page .profile-tabs {
  margin-top: 60px; }

.profile-page .gallery {
  margin-top: 45px;
  padding-bottom: 50px; }
  .profile-page .gallery img {
    width: 100%;
    margin-bottom: 30px; }

.signup-page .wrapper > .header {
  min-height: 100vh; }

.signup-page .wrapper .card-signup {
  margin: 160px 0 40px; }

.landing-page .navbar-transparent,
.profile-page .navbar-transparent,
.signup-page .navbar-transparent,
.index-page .navbar-transparent {
  padding-top: 25px; }

@media (max-width: 768px) {
  .footer .copyright {
    display: inline-block;
    text-align: center;
    padding: 10px 0;
    float: none !important;
    width: 100%; }
  .navbar.navbar-transparent {
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 10px;
    border-radius: 0; }
  .main-raised {
    margin-left: 10px;
    margin-right: 10px; } }

.navbar .navbar-nav > li > a {
  line-height: 35px; }

.navbar .navbar-brand {
  line-height: 40px; }

.center-pills {
  display: flex;
  justify-content: center; }

@media (max-width: 768px) {
  .navbar .navbar-brand {
    line-height: 30px; } }
